/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  var global_markers = [];
  var global_infowindows = [];

  var didScroll,
      lastScrollTop = 0,
      header = $('header.header'),
      navbarHeight = header.outerHeight(),
      delta = navbarHeight;


  /**
   * Check the scroll position and animate the top bar out if scrolled.
   */
  function hasScrolled() {
    var st = $(this).scrollTop();

    if (lastScrollTop > 0) {

      if (Math.abs(lastScrollTop - st) <= delta) {
        return;
      }

      // If they scrolled down and are past the navbar, add class .nav-up.
      // This is necessary so you never see what is "behind" the navbar.
      if (st > navbarHeight) {
        // Scroll Down
        header.removeClass('transparent').addClass('solid');
      } else {
        // Scroll Up
        header.removeClass('solid').addClass('transparent');
      }

    }//end last scroll > 0


    lastScrollTop = st;
  }//end hasScrolled


  function trigger_sticky_header () {

    //Get the header height, apply this as padding to body and then set header as fixed
    var header = $('header.header');

    $('body').css('padding-top', header.outerHeight());
    header.addClass('sticky');

  }


  function touch_device_postion_nav () {

    if ($('html').hasClass('touchevents') && parseInt($(window).width()) <= 991) {
      $('nav.navbar').css('top', parseInt($('header.header').outerHeight()));
      $('div.mobile-ddm-centered li.mobile-ddm').css('top', parseInt($('header.header').outerHeight()));
    } else {
      $('nav.navbar').css('top', 0);
    }

  }//end touch_device_postion_nav


  function footer_cols_padding () {
    if ($('footer#footer').hasClass('footer-logo-location_col')) {
      var img_h = $('.location-col img').height(),
          loc_col = $('.location-col'),
          widgets = $('div.widget');

      if (parseInt($(window).width()) >= 768) {
        widgets.css('padding-top', img_h);
        loc_col.css('margin-top', '-'+img_h+'px')
      } else {
        widgets.css('padding-top', 0);
        loc_col.css('margin-top', 0)
      }
    }
  }


  // Close infowindow function
  function closeInfowindows() {
    for (var x = 0; x < global_infowindows.length; x++) {
      global_infowindows[x].close();
    }
  }

  function set_menu_ddm_position () {
    var nav_items = $('ul[id^=menu-main-menu] li'),
        nav_items_count = nav_items.length;

    //Halfway point:
    var nav_mid_point = Math.round((nav_items_count / 2)),
        count = 0;

    nav_items.each(function(){

      if (count > nav_mid_point) {
        $(this).addClass('ddm-right');
      }

      count++;
    });

  }
  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {

        touch_device_postion_nav();
        //footer_cols_padding();

        //OnScroll
        if ($('section.hero.video-hero').length) {
          $(window).scroll(function () {
            didScroll = true;
          });

          setInterval(function () {
            if (didScroll) {
              hasScrolled();
              didScroll = false;
            }
          }, 50);
        }//end if has video section


        //Window resize
        $( window ).resize(function() {
          trigger_sticky_header();
          touch_device_postion_nav();
          //footer_cols_padding();
        });

        // Window load
        $(window).load(function () {

        });


        //Mobile nav menu click
        $(document).on('click', 'div.hamburger', function () {
          touch_device_postion_nav();
          $(this).toggleClass('is-active');
          $('nav.navbar').toggleClass('active');
          $('div.navbar-collapse').toggleClass('collapse');
          $('header.header').toggleClass('active');
        });



        //Close the mobile menu on body click
        $(document).on('click', 'body > main', function () {
          if ($('div.hamburger').hasClass('is-active')) {
            $('div.hamburger').trigger('click');
          }
        });
        //Mobile DDM booking click
        $('ul.mobile-book-ddm li.book').click(function(){
          $(this).toggleClass('active');
        });
        //Set DDM classes - left / right layout
        set_menu_ddm_position();
        //Touch device menu ddm clicks:
        $('ul[id^=menu-main-menu] li.menu-item-has-children.dropdown > a').click(function(e){

          if ($('html').hasClass('touchevents') && parseInt($(window).width()) > 991) {

            var $this = $(this);
            if ($this.attr('href') === '#') {
              e.preventDefault();
              return false;
            }

            //Unset any other items
            $('ul[id^=menu-main-menu] li.menu-item-has-children.dropdown > a').not(this).removeClass('nav-item-active');

            if (!$this.hasClass('nav-item-active')) {
              $this.addClass('nav-item-active');
              e.preventDefault();
              return false;
            }

          }

        });


        //Page scroll
        $("a.page-scroll").bind("click", function (e) {
          var offset = 75,
              t = $(this);
          $("html, body").stop().animate({
            scrollTop: (parseInt($(t.attr("href")).offset().top) - offset)
          }, 1500, "easeInOutExpo");
          e.preventDefault();
        });


        imagesLoaded('a.navbar-brand img', {background: false}, function () {
          setTimeout(function () {
            trigger_sticky_header();
          }, 1250);
        });


        //Video popups
        $('.video').magnificPopup({
          type: 'iframe',
          preloader: true,
          removalDelay: 500, //delay removal by X to allow out-animation
          callbacks: {
            beforeOpen: function () {
              // just a hack that adds mfp-anim class to markup
              this.st.image.markup = this.st.image.markup.replace('mfp-figure', 'mfp-figure mfp-with-anim');
              this.st.mainClass = this.st.el.attr('data-effect');
            }
          },
          closeOnContentClick: true
        });//end video modal



        //Image popups
        $('.magnific-gallery').each(function () {
          $(this).magnificPopup({
            delegate: 'a',
            type: 'image',
            preloader: true,
            gallery: {
              enabled: true
            },
            removalDelay: 500, //delay removal by X to allow out-animation
            callbacks: {
              beforeOpen: function () {
                // just a hack that adds mfp-anim class to markup
                this.st.image.markup = this.st.image.markup.replace('mfp-figure', 'mfp-figure mfp-with-anim');
                this.st.mainClass = this.st.el.attr('data-effect');
              }
            },
            closeOnContentClick: true,
            midClick: true // allow opening popup on middle mouse click. Always set it to true if you don't provide alternative source.
          });
        });//end image modal


        //Hero carousel
        $('.hero-slider').owlCarousel({
          items: 1,
          dots: false,
          nav: true,
          loop: true,
          animateOut: 'fadeOut',
          autoplay: true,
          autoplayHoverPause: true,
          autoplayTimeout: 6500,
          autoplaySpeed: 1500,
          navSpeed: 1500,
          navText: ['<i class="ion-chevron-left"></i>', '<i class="ion-chevron-right"></i>']
        });//end img carousel

        //Image carousel
        $('.image-carousel').owlCarousel({
          items: 1,
          dots: false,
          nav: true,
          navText: ['<i class="ion-chevron-left"></i>', '<i class="ion-chevron-right"></i>']
        });//end img carousel


        //Content carousel
        if ($('.content-carousel').length) {

          $('.content-carousel').each(function(){

            var items = $(this).data('items');

            $(this).owlCarousel({
              items: items,
              dots: true,
              nav: false,
              responsiveClass: true,
              margin: 15,
              responsive: {
                0: {
                  items: 1,
                  dots: true
                },
                767: {
                  items: 2,
                  dots: true
                },
                1024: {
                  items: 3,
                  dots: true
                },
                1000: {
                  items: items,
                  dots: true
                }
              }
            });//end img carousel

          });
        }//end content-carousel


        //Gallery carousel
        if ($('.gallery-carousel').length) {


          $('.gallery-carousel').each(function(){

            var items = $(this).data('items');

            $(this).owlCarousel({
              items: items,
              dots: true,
              nav: false,
              responsiveClass: true,
              responsive: {
                0: {
                  items: 2,
                  dots: true
                },
                767: {
                  items: 4,
                  dots: true
                },
                1000: {
                  items: items,
                  dots: true
                }
              }
            });//end img carousel

          });

        }//end gallery-carousel


        //Logos carousel
        if ($('.logos-carousel').length) {

          $('.logos-carousel').each(function(){

            var items = $(this).data('items');

            $(this).owlCarousel({
              items: items,
              dots: true,
              nav: false,
              responsiveClass: true,
              loop: true,
              autoplay: true,
              autoplayHoverPause: true,
              autoplaySpeed: 1500,
              dotsSpeed: 1500,
              responsive: {
                0: {
                  items: 2,
                  dots: true
                },
                767: {
                  items: 4,
                  dots: true
                },
                1000: {
                  items: items,
                  dots: true
                }
              }
            });//end img carousel

          });
        }//end content-carousel




        /**
         * initMap
         *
         * Renders a Google Map onto the selected jQuery element
         *
         * @date    22/10/19
         * @since   5.8.6
         *
         * @param   jQuery $el The jQuery element.
         * @return  object The map instance.
         */
        function initMap($el) {

          // Find marker elements within map.
          var $markers = $el.find('.marker');

          // Create generic map.
          var mapArgs = {
            zoom: $el.data('zoom') || 16,
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            disableDefaultUI: true,
            mapTypeControl: false
          };

          var args = {
            zoom: $el.data('zoom') || 16,
            center: new google.maps.LatLng(0, 0),
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            disableDefaultUI: true,
            mapTypeControl: false,
            styles: [
              {
                "featureType": "administrative",
                "elementType": "labels.text.fill",
                "stylers": [
                  {
                    "color": "#232d37"
                  }
                ]
              },
              {
                "featureType": "administrative.neighborhood",
                "elementType": "all",
                "stylers": [
                  {
                    "visibility": "off"
                  }
                ]
              },
              {
                "featureType": "landscape",
                "elementType": "all",
                "stylers": [
                  {
                    "color": "#eaeae7"
                  }
                ]
              },
              {
                "featureType": "poi",
                "elementType": "all",
                "stylers": [
                  {
                    "visibility": "off"
                  }
                ]
              },
              {
                "featureType": "road",
                "elementType": "all",
                "stylers": [
                  {
                    "saturation": -100
                  },
                  {
                    "lightness": 45
                  }
                ]
              },
              {
                "featureType": "road.highway",
                "elementType": "all",
                "stylers": [
                  {
                    "visibility": "off"
                  }
                ]
              },
              {
                "featureType": "road.arterial",
                "elementType": "labels.icon",
                "stylers": [
                  {
                    "visibility": "off"
                  }
                ]
              },
              {
                "featureType": "transit",
                "elementType": "all",
                "stylers": [
                  {
                    "visibility": "off"
                  }
                ]
              },
              {
                "featureType": "water",
                "elementType": "all",
                "stylers": [
                  {
                    "color": "#87e2fd"
                  },
                  {
                    "visibility": "on"
                  }
                ]
              }
            ]
          };
          var map = new google.maps.Map($el[0], args);

          // Add markers.
          map.markers = [];
          $markers.each(function () {
            initMarker($(this), map);
          });

          // Center map based on markers.
          centerMap(map);

          // Toggles between infowindows
          for (var i = 0; i < global_markers.length; i++) {
            // Keep value of 'i' in event creation
            (function (i) {
              google.maps.event.addListener(global_markers[i], 'click', function () {
                closeInfowindows();
                global_infowindows[i].open(map, global_markers[i]);
              });
            }(i));
          }

          // Return map instance.
          return map;
        }

        /**
         * initMarker
         *
         * Creates a marker for the given jQuery element and map.
         *
         * @date    22/10/19
         * @since   5.8.6
         *
         * @param   jQuery $el The jQuery element.
         * @param   object The map instance.
         * @return  object The marker instance.
         */
        function initMarker($marker, map) {

          // Get position from marker.
          var lat = $marker.data('lat');
          var lng = $marker.data('lng');

          var latLng = {
            lat: parseFloat(lat),
            lng: parseFloat(lng)
          };

          // Create marker instance.
          var marker = new google.maps.Marker({
            position: latLng,
            map: map,
            icon: {
              url: $marker.data('pin-icon'),
              scaledSize: new google.maps.Size(50, 50)
            }
          });

          // Append to reference for later use.
          map.markers.push(marker);
          global_markers.push(marker);

          // If marker contains HTML, add it to an infoWindow.
          if ($marker.html()) {

            // Create info window.
            var infowindow = new google.maps.InfoWindow({
              content: $marker.html()
            });

            global_infowindows.push(infowindow);

            // Show info window when marker is clicked.
            /*google.maps.event.addListener(marker, 'click', function () {
              infowindow.open(map, marker);
            });*/
          }
        }

        /**
         * centerMap
         *
         * Centers the map showing all markers in view.
         *
         * @date    22/10/19
         * @since   5.8.6
         *
         * @param   object The map instance.
         * @return  void
         */
        function centerMap(map) {

          // Create map boundaries from all map markers.
          var bounds = new google.maps.LatLngBounds();
          map.markers.forEach(function (marker) {
            bounds.extend({
              lat: marker.position.lat(),
              lng: marker.position.lng()
            });
          });

          // Case: Single marker.
          if (map.markers.length == 1) {
            map.setCenter(bounds.getCenter());

            // Case: Multiple markers.
          } else {
            map.fitBounds(bounds);
          }
        }

        $('.theme-map').each(function () {
          var map = initMap($(this));
        });

        //Map buttons click:
        if ($('button.map-button').length) {
          $('button.map-button').click(function(){
            var $this = $(this);
            google.maps.event.trigger(global_markers[$this.data('elem')], 'click');
            $('.map-buttons-row').removeClass('active');
          });
        }

        //Map select mobile
        if ($('a.cta.mobile-map-toggle').length) {
          $('a.cta.mobile-map-toggle').click(function(e){
            e.preventDefault();

            $(this).next('.map-buttons-row').toggleClass('active');
          });
        }




















        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
